import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { route } from "../routes/route";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";

import PretendardText from "../components/atoms/pretendardText";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import MainLayout from "../components/atoms/layouts/mainLayout";
import MainContainer from "../components/atoms/layouts/mainContainer";
import HeaderM from "../components/organisms/headerM";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";
import PaymentItem from "../components/organisms/paymentItem";

import useDisplay from "../hooks/useDisplay";

// ------  SDK 초기화 ------
// TODO: clientKey는 개발자센터의 API 개별 연동 키 > 결제창 연동에 사용하려할 MID > 클라이언트 키로 바꾸세요.
// TODO: server.js 의 secretKey 또한 결제위젯 연동 키가 아닌 API 개별 연동 키의 시크릿 키로 변경해야 합니다.
// TODO: 구매자의 고유 아이디를 불러와서 customerKey로 설정하세요. 이메일・전화번호와 같이 유추가 가능한 값은 안전하지 않습니다.
// @docs https://docs.tosspayments.com/sdk/v2/js#토스페이먼츠-초기화
const clientKey = process.env.REACT_APP_EDUATALK_CLIENT_KEY;
const customerKey = generateRandomString();

function generateRandomString() {
  // 난수와 현재 날짜 및 시간을 조합
  const randomPart = window.btoa(Math.random().toString()).slice(0, 20);
  const datePart = Date.now().toString(); // 또는 new Date().getTime().toString();

  return randomPart + datePart;
}

function ServicePayment(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();

  const packageProgram = [
    {
      title: "중3 2학기",
      content: "Pre 생기부레벨업",
      money: "100만원",
      pay: "1000000",
      key: "COURSE7",
      note: "※ 진로적성 포함",
      mNote: "※ 진로적성 포함",
    },
    {
      title: "고1~고3",
      content: "Pro 생기부레벨업",
      money: "180만원",
      pay: "1800000",
      key: "COURSE8",
      note: "※ 진로적성/생기부진단&설문포함",
      mNote: "※진로적성/생기부진단<br/>&설문포함",
    },
    {
      title: "고1~고3",
      content: "Master 생기부레벨업",
      money: "300만원",
      pay: "3000000",
      key: "COURSE9",
      note: "※ 진로적성/생기부진단&설문/2in1포함",
      mNote: "※ 진로적성/생기부진단&<br/>설문/2in1포함",
    },
  ];

  const basicProgram = [
    {
      title: "중3",
      content: "진로적성검사 Ⅰ",
      money: "30만원",
      pay: "300000",
      key: "COURSE10",
      note: "",
      mNote: "",
    },
    {
      title: "고1",
      content: "진로적성검사 Ⅱ",
      money: "40만원",
      pay: "400000",
      key: "COURSE11",
      note: "※ 고1, 5월까지 신청가능",
      mNote: "※ 고1, 5월까지 신청가능",
    },
    {
      title: "고1, 고2",
      content: "체험수업 1회 생기부레벨업",
      money: "40만원",
      pay: "400000",
      key: "COURSE12",
      note: "※ 7일 내 학기제 신청시, 공제 후 차액결제",
      mNote: "※ 7일 내 학기제 신청시,<br/>공제 후 차액결제",
    },
    {
      title: "고2, 고3",
      content: "생기부 진단 컨설팅",
      money: "50만원",
      pay: "500000",
      key: "COURSE13",
      note: "※ 7일 내 학기제 신청시, 공제 후 차액결제",
      mNote: "※ 7일 내 학기제 신청시,<br/>공제 후 차액결제",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);

    storageCheckFunc();
  }, []);

  const storageCheckFunc = async () => {
    try {
      let payment_data_temp = sessionStorage.getItem("payment_data");
      let saveData = JSON.parse(payment_data_temp);

      if (saveData) {
        setSaveData(saveData);
      }
      if (saveData && saveData.agree1) {
        setAgree1(saveData.agree1);
      }
    } catch (error) {}
  };

  const [saveData, setSaveData] = useState({
    inquiryType: "",
    service: null,
    name: "",
    phoneNumber: "",
    school: "",
  });
  const [agree1, setAgree1] = useState(false);

  const saveFunc = async () => {
    try {
      let saveDataTemp = JSON.stringify(saveData);
      localStorage.setItem("payment_data", saveDataTemp);

      await requestPayment();
    } catch (error) {
      toast("잠시후 다시 시도해주세요");
    }
  };

  const [payment, setPayment] = useState(null);

  useEffect(() => {
    async function fetchPayment() {
      try {
        const tossPayments = await loadTossPayments(clientKey);

        // 회원 결제
        // @docs https://docs.tosspayments.com/sdk/v2/js#tosspaymentspayment
        const payment = tossPayments.payment({
          customerKey,
        });
        // 비회원 결제
        // const payment = tossPayments.payment({ customerKey: ANONYMOUS });

        setPayment(payment);
      } catch (error) {
        console.error("Error fetching payment:", error);
      }
    }

    fetchPayment();
  }, [clientKey, customerKey]);

  // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
  // @docs https://docs.tosspayments.com/sdk/v2/js#paymentrequestpayment
  async function requestPayment() {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.

    payment.requestPayment({
      method: "CARD", // 카드 및 간편결제
      amount: {
        currency: "KRW",
        value: Number(saveData.service.pay),
      },
      orderId: generateRandomString(), // 고유 주문번호
      orderName: `${saveData.service.title} ${saveData.service.content}`,
      successUrl: window.location.origin + route.payment_success, // 결제 요청이 성공하면 리다이렉트되는 URL
      failUrl: window.location.origin + route.payment_fail, // 결제 요청이 실패하면 리다이렉트되는 URL
      customerEmail: "",
      customerName: saveData.name,
      customerMobilePhone: saveData.phoneNumber,
      card: {
        useEscrow: false,
        flowMode: "DEFAULT",
        useCardPoint: false,
        useAppCardOnly: false,
      },
    });
  }

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 60,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  결제하기
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                결제하기
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: isMobile ? 18 : 24,
                  lineHeight: isMobile ? "28.8px" : "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 30 : 11,
                }}
              >
                결제할 서비스를 골라주세요.
              </PretendardText>

              <div
                style={{
                  width: isMobile ? "90vw" : 1200,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 18,
                    lineHeight: "28.8px",
                    color: "#191F28",
                    fontWeight: 700,
                    marginBottom: isMobile ? 12 : 26,
                    textAlign: "center",
                  }}
                >
                  학기제 - 패키지 프로그램
                </PretendardText>
                <div
                  style={{
                    display: "grid",
                    width: isMobile ? "90vw" : "100%",
                    gridTemplateRows: "1fr ",
                    gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                    gridColumnGap: isMobile ? 15 : 23,
                    gridRowGap: isMobile ? "4.5vw" : 30,
                  }}
                >
                  {packageProgram.map(function (service) {
                    return (
                      <PaymentItem
                        key={service.key}
                        setSaveData={setSaveData}
                        saveData={saveData}
                        service={service}
                      />
                    );
                  })}
                </div>
              </div>

              <PretendardText
                style={{
                  fontSize: 12,
                  lineHeight: "19.2px",
                  color: "#FF6767",
                  fontWeight: 500,
                  marginBottom: isMobile ? 12 : 28,
                  marginTop: 11,
                  textAlign: isMobile ? "left" : "center",
                }}
              >
                ※ 매학기 연장시 할인 혜택
              </PretendardText>

              <div
                style={{
                  width: isMobile ? "90vw" : 1200,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 18,
                    lineHeight: "28.8px",
                    color: "#191F28",
                    fontWeight: 700,
                    marginBottom: isMobile ? 12 : 26,
                    textAlign: "center",
                  }}
                >
                  기본 프로그램
                </PretendardText>
                <div
                  style={{
                    display: "grid",
                    width: isMobile ? "90vw" : "100%",
                    gridTemplateRows: "1fr ",
                    gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                    gridColumnGap: isMobile ? 15 : 23,
                    gridRowGap: isMobile ? "4.5vw" : 30,
                  }}
                >
                  {basicProgram.map(function (service) {
                    return (
                      <PaymentItem
                        key={service.key}
                        setSaveData={setSaveData}
                        saveData={saveData}
                        service={service}
                      />
                    );
                  })}
                </div>
              </div>

              <div>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 18 : 24,
                    lineHeight: "38.4px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: isMobile ? 24 : 30,
                    marginTop: isMobile ? 32 : 42,
                  }}
                >
                  결제 방식을 선택하세요.
                </PretendardText>

                <div
                  style={{
                    display: "grid",
                    width: isMobile ? "90vw" : "100%",
                    gridTemplateRows: "1fr ",
                    gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                    gridColumnGap: isMobile ? 15 : 23,
                    gridRowGap: isMobile ? "4.5vw" : 30,
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "42.75vw" : 385,
                      height: isMobile ? "48vw" : 130,
                      borderRadius: 15,
                      border: "1px solid #353535",
                      background:
                        "linear-gradient(281.23deg, #00B3CE 0%, rgba(0, 179, 206, 0) 59.5%), #00B3CE",
                      boxShadow: "box-shadow: 4px 5px 13px 0px #00000040",
                      backgroundBlendMode: "multiply",
                      position: "relative",
                      padding: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => {}}
                  >
                    <div
                      style={{
                        display: "inline",
                        position: "absolute",
                        backgroundColor: "#0098BA",
                        borderRadius: 50,
                        top: 15,
                        left: 15,
                        width: 25,
                        height: 25,
                      }}
                    />
                    <img
                      src="/assets/icons/ic_check_2.png"
                      alt="Check Icon"
                      style={{
                        display: "inline",
                        position: "absolute",
                        top: 19,
                        left: 22,
                        width: 19,
                        height: 13,
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        borderRadius: 15,
                        border: "1.5px dashed #DDDDDD",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: isMobile ? 18 : 32,
                          lineHeight: isMobile ? "28.8px" : "44.8px",
                          color: "#FFFFFF",
                          fontWeight: 700,
                        }}
                      >
                        카드결제
                      </PretendardText>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                  paddingTop: 48,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 18 : 24,
                    lineHeight: "38.4px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: isMobile ? 30 : 26,
                  }}
                >
                  주문자 정보
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                    }}
                  >
                    학생명
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#00B3CE",
                      fontWeight: 700,
                    }}
                  >
                    *
                  </PretendardText>
                </div>

                <div
                  style={{
                    height: 50,
                    display: "flex",
                    borderBottom: "1px solid #DDDDDD",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <input
                    placeholder="이름을 입력해 주세요."
                    style={{
                      width: "100%",
                      border: "none",
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#191F28",
                      fontWeight: 400,
                      outline: "none",
                    }}
                    value={saveData.name}
                    onChange={(e) => {
                      setSaveData({ ...saveData, name: e.target.value });
                    }}
                  ></input>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                    }}
                  >
                    학교명
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#00B3CE",
                      fontWeight: 700,
                    }}
                  >
                    *
                  </PretendardText>
                </div>

                <div
                  style={{
                    height: 50,
                    display: "flex",
                    borderBottom: "1px solid #DDDDDD",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <input
                    placeholder="학교명을 입력해 주세요."
                    style={{
                      width: "100%",
                      border: "none",
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#191F28",
                      fontWeight: 400,
                      outline: "none",
                    }}
                    value={saveData.school}
                    onChange={(e) => {
                      setSaveData({ ...saveData, school: e.target.value });
                    }}
                  ></input>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    marginTop: 30,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                    }}
                  >
                    연락처
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#00B3CE",
                      fontWeight: 700,
                    }}
                  >
                    *
                  </PretendardText>
                </div>

                <div
                  style={{
                    height: 50,
                    display: "flex",
                    borderBottom: "1px solid #DDDDDD",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="number"
                    maxLength={15}
                    placeholder="숫자만 입력해주세요."
                    style={{
                      width: "100%",
                      border: "none",
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#191F28",
                      fontWeight: 400,
                      outline: "none",
                    }}
                    value={saveData.phoneNumber}
                    onChange={(e) => {
                      if (e.target.value.length > 15 || e.target.value < 0) {
                        return;
                      }
                      setSaveData({ ...saveData, phoneNumber: e.target.value });
                    }}
                  ></input>
                </div>

                <PretendardText
                  style={{
                    fontSize: isMobile ? 18 : 24,
                    lineHeight: "38.4px",
                    color: "#222222",
                    fontWeight: 700,
                    marginTop: isMobile ? 36 : 52,
                  }}
                >
                  필수 약관 동의
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: isMobile ? 29 : 20,
                  }}
                >
                  <img
                    src={
                      agree1
                        ? "/assets/icons/check_on.png"
                        : "/assets/icons/check_off.png"
                    }
                    alt={agree1 ? "Checked Icon" : "Unchecked Icon"}
                    style={{
                      width: 25,
                      height: 25,
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setAgree1(!agree1);
                    }}
                  />

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 400,
                      marginRight: 10,
                    }}
                  >
                    [필수] 환불정책에 동의합니다.
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#0036AF",
                      fontWeight: 400,
                      marginRight: 10,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let saveDataTemp = JSON.stringify({
                        ...saveData,
                        agree1: agree1,
                      });
                      sessionStorage.setItem("payment_data", saveDataTemp);

                      history.push(route.refund);
                    }}
                  >
                    내용보기
                  </PretendardText>
                </div>

                <div
                  style={{
                    marginTop: isMobile ? 45 : 48,
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    borderLeft: isMobile
                      ? "0px solid #E5E8EB"
                      : "1px solid #E5E8EB",
                    borderRight: isMobile
                      ? "0px solid #E5E8EB"
                      : "1px solid #E5E8EB",
                    borderBottom: isMobile
                      ? "0px solid #E5E8EB"
                      : "1px solid #E5E8EB",

                    borderRadius: isMobile ? 0 : 4,
                    borderTop: isMobile
                      ? "1px solid #E5E8EB"
                      : "1px solid #E5E8EB",
                    padding: isMobile ? "36px 0px 0px 0px" : 40,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      justifyContent: "space-between",
                      marginBottom: 32,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: isMobile ? 16 : 24,
                        lineHeight: isMobile ? "25.6px" : "38.4px",
                        color: "#222222",
                        fontWeight: 700,
                        marginRight: isMobile ? 0 : 10,
                        marginBottom: isMobile ? 15 : 0,
                      }}
                    >
                      최종 결제 금액
                    </PretendardText>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: isMobile
                            ? "space-between"
                            : "flex-start",
                        }}
                      >
                        <PretendardText
                          style={{
                            width: isMobile ? "auto" : 260,
                            fontSize: isMobile ? 14 : 18,
                            lineHeight: isMobile ? "22.4px" : "28.8px",
                            color: "#5A636A",
                            fontWeight: 500,
                          }}
                        >
                          {saveData.service
                            ? `${saveData.service.title} ${saveData.service.content}`
                            : "서비스명"}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#262C31",
                            fontWeight: 700,
                            marginRight: 5,
                            textAlign: "right",
                            flex: 1,
                          }}
                        >
                          {saveData.service
                            ? Number(saveData.service.pay).toLocaleString(
                                "ko-KR"
                              )
                            : "+ 0"}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 15,
                            lineHeight: "22.3px",
                            color: "#262C31",
                            fontWeight: 500,
                          }}
                        >
                          원
                        </PretendardText>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: isMobile ? 19 : 26,
                        }}
                      >
                        <PretendardText
                          style={{
                            width: isMobile ? "auto" : 260,
                            fontSize: isMobile ? 16 : 18,
                            lineHeight: isMobile ? "25.6px" : "28.8px",
                            color: "#191F28",
                            fontWeight: 700,
                          }}
                        >
                          최종 결제 금액
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 24,
                            lineHeight: "38.4px",
                            color: "#F01010",
                            fontWeight: 700,
                            marginRight: 5,
                            textAlign: "right",
                            flex: 1,
                          }}
                        >
                          {saveData.service
                            ? Number(saveData.service.pay).toLocaleString(
                                "ko-KR"
                              )
                            : "0"}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 15,
                            lineHeight: "22.3px",
                            color: "#262C31",
                            fontWeight: 500,
                          }}
                        >
                          원
                        </PretendardText>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      margin: isMobile ? "0px" : "0px 9px",
                      height: 46,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: 15,
                      background:
                        "linear-gradient(281.23deg, #00B3CE 0%, rgba(0, 179, 206, 0) 59.5%), #00B3CE",
                      boxShadow: "box-shadow: 4px 5px 13px 0px #00000040",
                      backgroundBlendMode: "multiply",
                      position: "relative",
                      padding: 5,
                    }}
                    onClick={() => {
                      if (!saveData.inquiryType) {
                        return toast("서비스유형을 선택해주세요");
                      }
                      if (!saveData.name) {
                        return toast("이름을 입력해주세요");
                      }
                      if (!saveData.school) {
                        return toast("학교명을 입력해주세요");
                      }
                      if (!saveData.phoneNumber) {
                        return toast("연락처를 입력해주세요");
                      }
                      if (!agree1) {
                        return toast("필수동의항목에 동의해주세요.");
                      }

                      saveFunc();
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 20,
                        lineHeight: "32px",
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }}
                    >
                      결제하기
                    </PretendardText>
                  </div>
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default ServicePayment;
